<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Subscription Details" @close-sidebar="closeSidebar">
                <sidebar-details :uuid="UUID" @close-sidebar="closeSidebar"></sidebar-details>
            </app-sidebar-details>
        </template>

        <div class="page-matrixx-sp-groups container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Subscriptions</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-8 col-tab-12">
                    <subscriptions-table 
                        :current-page="currentSubscriptionPage"
                        :row-uuid="UUID"
                        @row-click="showDetails"
                        @row-click-mobile="openDetails"
                    />

                </div>
                <div class="col-4 col-tab-12">
                    <app-adding-banner 
                        header="Add Subscriptions" 
                        description="Easily add new subscriptions within Matrixx"
                        :route="{name: 'matrixx-add-subscription'}"
                        action-title="Add"
                        image="service"
                    />
                </div>
            </div>
        </div>

        <app-loader v-if="loading"></app-loader>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appSidebarDetails from '@/components/app-sidebar-details'
import sidebarDetails from '@/views/matrixx/subscription-details'

import appAddingBanner from '@/components/app-adding-banner'
import subscriptionsTable from './subscriptions-table'

export default {
    components: {
        baseLayout,

        appSidebarDetails,
        sidebarDetails,

        appAddingBanner,
        subscriptionsTable,
    },

    data() {
        return {
            UUID: '', // selected row

            loading: false,
            currentSubscriptionPage: 1
        }
    },

    methods: {
        init(to, from) {
            this.currentSubscriptionPage = to.params.page
        },

        /**
         * Service Details
         */
        openDetails(row) {
            this.$router.push({ name: 'matrixx-subscription-details', params: { uuid: row.UUID } })
        },

        showDetails(row) {
            if (this.UUID == row.UUID) {
                this.closeSidebar()
            } else {
                this.UUID = row.UUID
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.UUID = null
            this.$emit('close-sidebar')
        },
    },

    computed: {
        ...mapGetters([
            'current_sp',
            'current_spid',

            'is_super_admin',
            'current_sp_roles',
            'available_service_providers',
        ])
    },
    
    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-matrixx-sp-groups {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .subscriptions-table {
        @include table-cols-width((250px, 120px, 200px, 120px, 85px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-matrixx-sp-groups {
        .subscriptions-table {
            margin-bottom: 30px;
            
            @include table-cols-width((150px, 100px, 150px, 100px, 85px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-matrixx-sp-groups {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .subscriptions-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((85px, 190px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>